import * as React from "react"
//import { Link } from "gatsby"
//import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import AboutYou from '../components/careers/aboutyou'
import SayAbout from "../components/home/sayabout"
import "./mainpage.scss"

import Header from "../components/services/header"
import Projects from "../components/home/projects"
import Capabilities from "../components/services/capabilities"
//import { Row, Col } from "react-bootstrap"
// import { Row, Col } from "react-bootstrap"
import { graphql } from "gatsby"
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next"


const ServicesPage = ({ location }) => {
    const { i18n } = useI18next()
    const { t } = useTranslation()
    return (
        <Layout headerTransparent={true} lang={i18n.resolvedLanguage}>
            <div className="max-container-pages">
                <Seo title={t("title", { ns: 'services' })} lang={i18n.resolvedLanguage} description={t("seo.description", { ns: 'common' })} />
                <Header></Header>
                <Capabilities></Capabilities>
                <Projects previous={location.pathname}></Projects>
                <SayAbout className="about-service-view"></SayAbout>
                <AboutYou></AboutYou>
            </div>
        </Layout >
    )
}

export default ServicesPage
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "services"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`


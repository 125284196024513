import { StaticImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import * as React from "react";
import { Button, Col, Form, Row } from 'react-bootstrap';
import ReactDOMServer from 'react-dom/server';
import JoinbtsModal from "../refer/joinbtsmodal";
import "./applynowform.scss";
import SocialMedia from "./socialmedia";

import { useTranslation } from "gatsby-plugin-react-i18next";
import filesLib from '../../lib/files';
import xhr from '../../lib/xhr';

const { getFileData } = filesLib;

const fileSizeLimit = 4 * 1024 * 1024;

const ApplyNowForm = ({ title = ["Let's connect!", "Join our Talent Network"], formVariant = "default", job }) => {
    const { t } = useTranslation("common", { keyPrefix: "formAboutYou" });
    const GATSBY_CONTACT_ENDPOINT = process.env.GATSBY_CONTACT_ENDPOINT;
    let formButton = t("Submit");
    let resume = false;
    let msgType = null;
    let formEvent = null;
    let contactEmail = "lorenas@bluetrailsoft.com";

    switch (formVariant) {
        case "signme":
            formButton = t("Sign me up");
            msgType = 'signme';
            formEvent = title[1];
            break;
        case "join":
            resume = true;
            formButton = t("Join");
            msgType = 'join';
            contactEmail = "lorenas@bluetrailsoft.com"
            break;
        default:
            break;
    }
   
    /** Send Form logic */
    const [status, setStatus] = React.useState('initial');
    const [file, setFile] = React.useState();
    const [category, setCategory] = React.useState(job);

    const form = React.useRef(null);

    const fileLimitExceed = file && file.size > fileSizeLimit;
    const isSending = status === 'sending';

    const handleSubmit = event => {
        event.preventDefault();
        setStatus('sending');

        const formData = new FormData(form.current);
        const body = {};
        const formValues = {};

        for (let element of form.current.elements) {
            if (element.required && element.type === "text" || element.tagName === "TEXTAREA") {
                const value = formData.get(element.name);
                if (!value || value.trim().length < 1) {
                    setStatus('initial')
                    return;
                }
            }
        }

        for (let [k, v] of formData) {
            if (k !== 'file') {
                formValues[k] = v;

            }
        }

        if (file) {
            body.file = file;
        }

        body.title = title[0] + " - " + title[1];
        body.type = msgType;
        body.name = formValues.name;
        body.email = formValues.email;
        body.job = job ? job : null;
        body.event = formEvent ? formEvent : null;

        const formValuesList = Object.keys(formValues).map((keyName, i) => (
            <li key={i}>
                <b>{keyName}: </b>{formValues[keyName]}
            </li>
        ));
        const msg = <ul>{formValuesList}</ul>;
        body.msg = ReactDOMServer.renderToStaticMarkup(msg);


        xhr(GATSBY_CONTACT_ENDPOINT, body)
            .then(() => setStatus('success'))
            .catch(() => setStatus('error'))

        return false;
    }


    function cleanStatus(e) {
        e.preventDefault();
        setStatus('initial');
    }

    const categoryChange = (e) => {
        setCategory(e.value);
    }

    return (
        <section className="section-applynowform-wrapper">
            <Row className={(status === 'initial' || status === 'sending' ? "" : "d-none ") + "wrapper-applynowform"}>
                <Col lg={7}>
                    <section className="section-applynowform">
                        <Row className="row-applynowform">
                            {formVariant !== "join" && <Row className="justify-content-end">
                                <div className="logo d-none d-lg-block">
                                    <StaticImage
                                        className="main-logo"
                                        src="../../images/layout/Logotype@3x.png"
                                        alt="BTS-logo"
                                        width={285}
                                        quality={95}
                                        formats={["auto", "webp", "avif"]}
                                    />
                                </div>
                            </Row>}
                            <Row>
                                <Col md={4}></Col>
                                <Col className="col-right" md={8}>
                                    <h2>{title[0]}</h2>
                                    <h3>{title[1]}</h3>
                                </Col>
                            </Row>
                            <Form method="POST" ref={form} onSubmit={handleSubmit}>
                                <Form.Group className="mb-3" controlId="name">
                                    <Row>
                                        <Col className="col-left" md={4}>
                                            <Form.Label>{t("First Name")} <span className="text-red">*</span></Form.Label>
                                        </Col>
                                        <Col className="col-right" md={8}>
                                            <Form.Control type="text" name="name" required />
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="lastName">
                                    <Row>
                                        <Col className="col-left" md={4}>
                                            <Form.Label>{t("Last Name")} <span className="text-red">*</span></Form.Label>
                                        </Col>
                                        <Col className="col-right" md={8}>
                                            <Form.Control type="text" name="lastname" required />
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="email">
                                    <Row>
                                        <Col className="col-left" md={4}>
                                            <Form.Label>{t("Primary Email")} <span className="text-red">*</span></Form.Label>
                                        </Col>
                                        <Col className="col-right" md={8}>
                                            <Form.Control type="email" name="email" required />
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="experience">
                                    <Row>
                                        <Col className="col-left" md={4}>
                                            <Form.Label>{t("Experience Level")} <span className="text-red">*</span></Form.Label>
                                        </Col>
                                        <Col className="col-right" md={8}>
                                            <Form.Control type="text" name="experience" required />
                                        </Col>
                                    </Row>
                                </Form.Group>
                                {formVariant === "join" && <Form.Group className="mb-3" controlId="areas">
                                    <Row>
                                        <Col className="col-left" md={4}>
                                            <Form.Label>{t("Area(s) of Interest")} <span className="text-red">*</span></Form.Label>
                                        </Col>
                                        <Col className="col-right" md={8}>
                                            <Form.Control type="text" name="areas" required />
                                        </Col>
                                    </Row>
                                </Form.Group>}
                                {formVariant === "join" && <Form.Group className="mb-3" controlId="skills">
                                    <Row>
                                        <Col className="col-left" md={4}>
                                            <Form.Label>{t("Primary Skill Set")} <span className="text-red">*</span></Form.Label>
                                        </Col>
                                        <Col className="col-right" md={8}>
                                            <Form.Control type="text" name="skills" required />
                                        </Col>
                                    </Row>
                                </Form.Group>
                                }
                                <Form.Group className="mb-3" controlId="country">
                                    <Row>
                                        <Col className="col-left" md={4}>
                                            <Form.Label>{t("Primary Country")} <span className="text-red">*</span></Form.Label>
                                        </Col>
                                        <Col className="col-right" md={8}>
                                            <Form.Control type="text" name="country" required />
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="jobCategory">
                                    <Row>
                                        <Col className="col-left" md={4}>
                                            <Form.Label>{t("Job Category")} </Form.Label>
                                        </Col>
                                        <Col className="col-right" md={8}>
                                            <Form.Control type="text" name="jobCategory" value={category} onChange={(e) => categoryChange(e)} />
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="countryInterest">
                                    <Row>
                                        <Col className="col-left" md={4}>
                                            <Form.Label>{t("Primary Country of Interest")} </Form.Label>
                                        </Col>
                                        <Col className="col-right" md={8}>
                                            <Form.Control type="text" name="countryInterest" />
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="file">
                                    <Row>
                                        <Col className="col-left" md={4}>
                                            <Form.Label className="label-file">
                                                {resume ? t("Resume") : t("Attachment")}{resume && <span className="text-red">*</span>}
                                            </Form.Label>
                                        </Col>
                                        <Col className="col-right " md={8}>
                                            <Row>
                                                <Col className="col-attach" xs={4}>
                                                    <div className="form-file-container">
                                                        <StaticImage
                                                            className="attach-icon"
                                                            src="../../images/icons/attach-icon-green.svg"
                                                            alt="Attach File icon"
                                                            width={41}
                                                            quality={100}
                                                            formats={["auto", "webp", "avif"]}
                                                        />
                                                        <Form.Control
                                                            className="form-file-input"
                                                            type="file"
                                                            name="file"
                                                            required={resume}
                                                            onChange={async event => {
                                                                if (event.target.files && event.target.files[0]) {
                                                                    setFile(await getFileData(event.target.files[0]))
                                                                }
                                                            }}
                                                        />
                                                        <Form.Text className="text-muted">
                                                            {t("Up to 4Mb")}
                                                        </Form.Text>
                                                    </div>
                                                </Col>
                                                <Col className="col-name" xs={8}>
                                                    <div className="file-list-container">
                                                        {file && <span className="file-name">
                                                            {file.name}
                                                        </span>
                                                        }

                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <span className={(fileLimitExceed ? "warning " : "") + "message"}>
                                                        {(fileLimitExceed ? t("The uploaded file exceeds the 4 Mb") : " ")}
                                                    </span>
                                                    <Button variant="success" type="submit" disabled={fileLimitExceed || isSending}>
                                                        {formButton} {isSending && <span className="loader" />}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Row>
                                    <Col md={4}> </Col>
                                    <Col className="col-right" md={8}>
                                        {formVariant === "join" && <span className="caption caption-bottom">
                                            {t("By clicking “Join” you agree to receive career opportunity information from BTS in accordance with the BTS Data Privacy Policy.")}
                                        </span>}
                                    </Col>
                                </Row>
                            </Form>
                        </Row>
                    </section>
                </Col>
                <Col className="d-none d-lg-block" lg={5}>
                    <JoinbtsModal></JoinbtsModal>
                </Col>
            </Row>
            <div className={(status === 'success' ? "" : "d-none ") + "thank-you"}>
                <h2>{t("Thanks")}</h2>
                <h4>{t("All Set! Our representative will contact you soon")}</h4>
                <Button className="main-page-link" variant="success" href="/">{t("Main page")}</Button>
                <SocialMedia></SocialMedia>
                <StaticImage
                    className="main-logo"
                    src="../../images/layout/Logotype@3x.png"
                    alt="BTS-logo"
                    width={285}
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                />
            </div>
            <div className={(status === 'error' ? "" : "d-none ") + "thank-you"}>
                <h2>{t("ERROR")}</h2>
                <h4>
                    {t("Something went wrong. Please check your internet connection and try again.")}<br></br>
                    {t("If the error continues, please send a message directly to", { email: contactEmail })} 
                </h4>
                <Button className="main-page-link" variant="success" onClick={(e) => cleanStatus(e)} >{t("Try again")}</Button>
                <SocialMedia></SocialMedia>
                <StaticImage
                    className="main-logo"
                    src="../../images/layout/Logotype@3x.png"
                    alt="BTS-logo"
                    width={285}
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                />
            </div>
        </section>
    )
}

ApplyNowForm.propTypes = {
    id: PropTypes.string,
}

ApplyNowForm.defaultProps = {
    id: ``,
}

export default ApplyNowForm

import { Link, useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"
import { Button, Col, Container, Row } from 'react-bootstrap'
import ButtonContact from "../layout/buttoncontact"
import ServiceNavigation from './servicenavigation'
import "./capabilities.scss"

const Capabilities = ({ className }) => {
    const { t } = useTranslation("services", { keyPrefix: "Capabilities" })
    return (
        <section id="capabilities" className={(className ? className + " " : "") + "capabilities"}>
            <Container fluid >
                <div className="max-container">
                    <ServiceNavigation></ServiceNavigation>

                    <Row className="parent-div justify-content-around">
                        <Col className="capa-products-pad" xs={12} sm={12} md={10} lg={10} xl={10}  >
                            <div className="capa-products bg-icon-corner">
                                <Col className="capa-title" xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <div className="icon-corner b-icon-7"></div>
                                    {t("Custom Software Development")}
                                </Col>
                                <Col className="capa-text" xs={12} sm={12} md={12} lg={12} xl={12}>
                                    {t("We offer full software design")}
                                </Col>
                                <Col className="capa-button" xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <ButtonContact title={t("Learn more about B2B B2C B2B2C")} formVariant="green" variant="link" closeLabel={t("Close")}>{t("Learn More")} 	&#10140;</ButtonContact>
                                </Col>
                            </div>
                        </Col>
                        <Col className="capa-products-pad" xs={12} sm={12} md={6} lg={6} xl={6} >
                            <div className="capa-products bg-icon-corner">
                                <Col className="capa-title" xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <div className="icon-corner b-icon-6"></div>
                                    {t("Mobile Platforms")}
                                </Col>
                                <Col className="capa-text" xs={12} sm={12} md={12} lg={12} xl={12}>
                                    {t("BTS has in-depth experience in developing applications for the most popular mobile platforms")}
                                </Col>
                                <Col className="capa-button" xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <ButtonContact title={t("Learn more about Mobile Platforms")} formVariant="green" variant="link" closeLabel={t("Close")}>{t("Learn More")} 	&#10140;</ButtonContact>
                                </Col>
                            </div>
                        </Col>



                        <Col className="capa-products-pad" xs={12} sm={12} md={6} lg={6} xl={6}  >

                            <div className="capa-products bg-icon-corner">
                                <Col className="capa-title" xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <div className="icon-corner b-icon-5"></div>
                                    {t("User Experience")}
                                </Col>
                                <Col className="capa-text" xs={12} sm={12} md={12} lg={12} xl={12}>
                                    {t("Our User Experience team covers the 4 steps of UX and can also design the UI")}
                                </Col>
                                <Col className="capa-button" xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <ButtonContact title={t("Learn more about UX / UI")} formVariant="green" variant="link" closeLabel={t("Close")}>{t("Learn More")} 	&#10140;</ButtonContact>
                                </Col>
                            </div>

                        </Col>


                        <Col className="capa-products-pad" xs={12} sm={12} md={6} lg={6} xl={6}  >
                            <div className="capa-products bg-icon-corner">
                                <Col className="capa-title" xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <div className="icon-corner b-icon-2"></div>
                                    {t("Machine Learning")}
                                </Col>
                                <Col className="capa-text" xs={12} sm={12} md={12} lg={12} xl={12}>
                                    {t("We specialize in enriching existing text data with public data")}
                                </Col>
                                <Col className="capa-button" xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <ButtonContact title={t("Learn more about Machine Learning")} formVariant="green" variant="link" closeLabel={t("Close")}>{t("Learn More")} 	&#10140;</ButtonContact>
                                </Col>
                            </div>
                        </Col>
                        <Col className="capa-products-pad" xs={12} sm={12} md={6} lg={6} xl={6}  >
                            <div className="capa-products bg-icon-corner">
                                <Col className="capa-title" xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <div className="icon-corner b-icon-3"></div>
                                    {t("Technical Support")}
                                </Col>
                                <Col className="capa-text" xs={12} sm={12} md={12} lg={12} xl={12}>
                                    {t("We are equipped to offer")}
                                </Col>
                                <Col className="capa-button" xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <ButtonContact title={t("Learn more about Technical Support")} formVariant="green" variant="link" closeLabel={t("Close")}>{t("Learn More")} 	&#10140;</ButtonContact>
                                </Col>
                            </div>
                        </Col>
                        <Col className="capa-products-pad" xs={12} sm={12} md={6} lg={6} xl={6}  >
                            <div className="capa-products bg-icon-corner">
                                <Col className="capa-title" xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <div className="icon-corner b-icon-4"></div>
                                    {t("DevSecOps")}
                                </Col>
                                <Col className="capa-text" xs={12} sm={12} md={12} lg={12} xl={12}>
                                    {t("We provide DevSecOps managed services")}
                                </Col>
                                <Col className="capa-button" xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <ButtonContact title={t("Learn more about DevSecOps")} formVariant="green" variant="link" closeLabel={t("Close")}>{t("Learn More")} 	&#10140;</ButtonContact>
                                </Col>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section >
    )
}

export default Capabilities

import { useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"
import { Col, Container, Row } from 'react-bootstrap'
import ButtonContact from "../layout/buttoncontact"
import "./header.scss"

const HeaderServices = ({ className }) => {
    const { t } = useTranslation("services", { keyPrefix: "HeaderServices" })
    return (
        <section className={(className ? className + " " : "") + "header-services"}>
            <Container fluid>
                <Row className="back-header-services">
                    <Col className="back-header-text-services" xs={12} sm={12} md={7} lg={7}>
                        <span className="our-offer">{t("Our Offer")}</span>
                        <span className="development-services">{t("Development Services")}</span>
                        <p>
                            {t("This is our core activity")}
                        </p>
                        <ButtonContact title={t("Want to chat about your idea")} formVariant="green" closeLabel={t("Close")}>{t("Tell us more about your project")}</ButtonContact>
                    </Col>
                </Row>
            </Container>
        </section >
    )
}

export default HeaderServices
